import { EVENTS, handleMessage, initEvents } from "@/__main__/ipc-core.mjs";
import { setRoute } from "@/__main__/router.mjs";
import { setVolatileKV } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { GAME_SYMBOL_DEADLOCK } from "@/game-deadlock/definition.mjs";
import {
  EVENT_DEADLOCK_ENTER_GAME,
  EVENT_DEADLOCK_EXIT_GAME,
  EVENT_DEADLOCK_HEARTBEAT,
  EVENT_DEADLOCK_METADATA,
} from "@/game-deadlock/utils/deadlock-client.api.mjs";
import { devDebug } from "@/util/dev.mjs";
import { updateLatestPlayed } from "@/util/game-handlers.mjs";
import { findGameSymbol } from "@/util/game-route.mjs";
import { steam3to64 } from "@/util/steam.mjs";

async function initDeadlock() {
  await initEvents;

  handleMessage(
    EVENTS.DEADLOCK_HEARTBEAT,
    (payload: { sessionId: string; steamId?: number; type: string }) => {
      devDebug("[Deadlock] Received DEADLOCK_HEARTBEAT", payload);
      const steam3 = payload?.steamId;
      eventBus.emit(EVENT_DEADLOCK_HEARTBEAT, {
        sessionId: payload.sessionId,
        type: payload.type,
        ...(steam3 && {
          steam3,
          steam64: Number.parseInt(steam3to64(steam3), 10),
        }),
      });
    },
  );

  handleMessage(
    EVENTS.DEADLOCK_IS_RUNNING,
    (payload: { running: boolean; steamId?: number }) => {
      devDebug("[Deadlock] Received DEADLOCK_IS_RUNNING", payload);
      const isRunning = payload.running;
      if (!isRunning) {
        setVolatileKV("currentGameTuple", null);
        return;
      }
      setVolatileKV("currentGameTuple", [GAME_SYMBOL_DEADLOCK]);
      updateLatestPlayed(GAME_SYMBOL_DEADLOCK);
      if (isRunning && findGameSymbol() !== GAME_SYMBOL_DEADLOCK) {
        // once we have profiles setup, for now we'll just route to tierlist
        // const steam3 = payload.steamId;
        // if (steam3) {
        //   setRoute(`/deadlock/profile/${steam3}`);
        // }
        setRoute("/deadlock/tierlist");
      }
    },
  );

  handleMessage(EVENTS.DEADLOCK_GAME_END, (payload: unknown) => {
    devDebug("[Deadlock] Received DEADLOCK_GAME_END", payload);
    eventBus.emit(EVENT_DEADLOCK_EXIT_GAME, payload);
  });

  handleMessage(
    EVENTS.DEADLOCK_GAME_START,
    (payload: {
      match_mode: string | number;
      match_id: string | number;
      start_time: number;
    }) => {
      devDebug("[Deadlock] Received DEADLOCK_GAME_START", payload);
      eventBus.emit(EVENT_DEADLOCK_ENTER_GAME, payload);
    },
  );

  handleMessage(
    EVENTS.DEADLOCK_METADATA_UPDATED,
    (payload: {
      timestamp: number;
      metadata: {
        match_id: number;
        match_metadata: {
          cluster_id: number;
          metadata_salt: number;
          replay_salt: number;
          result: number;
        };
      };
    }) => {
      devDebug("[Deadlock] Received DEADLOCK_METADATA_UPDATED", payload);
      eventBus.emit(EVENT_DEADLOCK_METADATA, payload);
    },
  );

  handleMessage(
    EVENTS.DEADLOCK_GAME_PHASE_CHANGED,
    (payload: DeadlockGamePhaseChanged) => {
      devDebug("[Deadlock] Received DEADLOCK_GAME_PHASE_CHANGED", payload);
    },
  );
}

export default initDeadlock;

type DeadlockGamePhaseChanged = {
  match_mode: number;
  match_id: number;
  start_time: number;
  events: Array<unknown>;
  local_player: unknown;
  rules: {
    m_bDontUploadStats: boolean;
    m_bEnemyInAmberBase: boolean;
    m_bEnemyInSapphireBase: boolean;
    m_bFastCooldownsEnabled: boolean;
    m_bFlexSlotsForcedUnlocked: boolean;
    m_bInfiniteResourcesEnabled: boolean;
    m_bMatchSafeToAbandon: boolean;
    m_bNoDeathEnabled: boolean;
    m_bServerPaused: boolean;
    m_bStaminaCooldownsEnabled: boolean;
    m_eGGTeam: number;
    m_eGameState: number;
    m_eMatchMode: number;
    m_fLevelStartTime: number;
    m_flGGEndsAtTime: number;
    m_flGameStartTime: number;
    m_flHeroDiedTime: number;
    m_flPauseTime: number;
    m_flPreGameWaitEndTime: number;
    m_flReportCardDismissalWaitStart: number;
    m_flRoundStartTime: number;
    m_iPauseTeam: number;
    m_nExperimentalGameplayState: number;
    m_nGameOverEvent: number;
    m_nLastPreGameCount: number;
    m_nPlayerDeathEventID: number;
    m_nReplayChangedEvent: number;
    m_pausingPlayerId: number;
    m_unMatchID: number;
    m_unpausingPlayerId: number;
  };
  game_phase: number;
};
